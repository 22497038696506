




























































































import {
    Component,
    Vue
} from "vue-property-decorator";
import {
    Core
} from "@/store/core";
import {
    User
} from "@/store/user";
import _ from "lodash";
@Component({
    components: {},
})
export default class OitMain extends Vue {
    private years: any = null;
    private user: any = {
      ext_link: {
        agency: null,
        oit_up: null,
      },
    };
    private response: boolean = false;
    up: any = 21;
    uptxt: any = "";
    upAll: any = [];
    public async created() {
      this.response = false
      let loader = await this.$loading.show()
        this.user = await User.getUser();
        this.years = await Core.getHttp(`/api/ita/v2/year/`);

        for (let i = 0; i < this.years.length; i++) {
            let res = await Core.getHttp(
                `/api/ita/v1/rateresult/?rate__year=${this.years[i].id}&agency=${this.user.ext_link.agency}`
            );
            let rate = await Core.getHttp(`/api/ita/v2/rate/${this.years[i].id}/`);
            this.years[i].result = await this.getOITResult(res);
            this.years[i].rate = rate.length;
        }
        await this.getUP();
        this.response = true;
        await loader.hide()
    }

    public async getUP() {
        let yesrs = await Core.getHttp(`/api/ita/v2/year/`);
        for (let i = 0; i < yesrs.length; i++) {
            let res = await Core.getHttp(
                `/api/ita/v1/rateresult/?rate__year=${this.years[i].id}&agency=${this.up}`
            );
            let rate = await Core.getHttp(`/api/ita/v2/rate/${this.years[i].id}/`);
            this.upAll[i] = yesrs[i];
            this.upAll[i].result = await this.getOITResult(res);
            this.upAll[i].rate = rate.length;
        }
    }

    private async getOITResult(raw: any) {
        let result = await _(raw)
            .groupBy("rate")
            .map(function (items, data) {
                return {
                    data: data,
                };
            })
            .value();
        return result.length;
    }
}
